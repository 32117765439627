module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":[""],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CÔNG TY CỔ PHẦN CÔNG NGHỆ IBASE","short_name":"iBASE","start_url":"/","icon":"static/favicon_ibase.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"62f862eb220b25c7afb582980b2fcfe2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"C:\\Code\\CVS\\ibase-demo-web/src/intl","languages":["vi","en"],"defaultLanguage":"vi"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
